
@import '../assets/css/scss-variables.scss';

.section {
  & + .section {
    margin-top: var(--spacer-padding);
  }

  & + .section.section-contact {
    margin-top: calc(var(--spacer-padding) * 2);
  }

  &.section-showcase {
    margin-top: calc(var(--spacer-padding) * 1.5) !important;
  }

  &.section-text {
    text-align: center;

    & > * + * {
      margin-top: var(--spacer-padding);
    }
  }
}

*[id] {
  scroll-margin: var(--spacer);

  @media (pointer: fine) {
    scroll-margin: calc(var(--navigation-bar-height) + (var(--spacer) * 0.8));
  }

  @media (min-width: $mediaquery-tablet-landscape) {
    scroll-margin: calc(var(--navigation-bar-height) + (var(--spacer) * 0.8));
  }
}

.contact-button-holder {
  display: flex;
  gap: calc(var(--spacer) * .5);
  justify-content: center;
  flex-direction: column;

  @media (min-width: $mediaquery-tablet-portrait) {
    flex-direction: row;
  }

  a.button.bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-width: 0;
    font-weight: 800;
    font-size: var(--font-size-headline-bodytext);
    color: inherit;

    @media (max-width: $mediaquery-tablet-portrait) {
      max-width: unset;
    }

    --gray-button-background: rgba(230, 230, 230);
    --gray-button-background-active: rgba(220, 220, 220);
    --gray-button-border: rgba(210, 210, 210);
    --button-depth: .25em;
    background-color: var(--gray-button-background);
    border-style: unset;
    border-width: unset;
    border-color: unset;
    box-shadow: 0px 0em 0px var(--gray-button-border);
    transition:
      box-shadow         var(--transition-speed) var(--transition-easing),
      transform          var(--transition-speed) var(--transition-easing),
      background-color   var(--transition-speed) var(--transition-easing),
      color              var(--transition-speed) var(--transition-easing);

    @media (hover: hover) {
      &:hover {
        box-shadow: 0px calc(var(--button-depth) * 1) var(--gray-button-border);
        transform: translateY(calc(var(--button-depth) * -1));
      }
    }

    &:active {
      box-shadow: 0px calc(var(--button-depth) * 0) var(--gray-button-border);
      transform: translateY(0em);
      background-color: var(--gray-button-background-active);
    }
  }
}

