
	.comp-card {
		display: inline-block;
		width: 100%;
		box-sizing: border-box;
    position: relative;
		margin-bottom: var(--spacer-padding);

		/*Remove webkit tap highligh on touch devices*/
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		text-decoration: none;
		color: inherit;

		// &.browser-dots::after {
		// 	content: '•••';
		// 	font-size: 1.25em;
		// 	line-height: var(--browser-bar-height);
		// 	position: absolute;
		// 	z-index: 10;
		// 	color: white;
		// 	left: 5px;
		// 	top: calc(var(--browser-bar-height) * -0.15);
		// 	pointer-events: none;
		// }
	}

	.card-image {
		position: relative;
		overflow: hidden;

		border-radius: var(--border-radius);
		background-color: var(--gray-light);
		animation: animation-fade-in .5s ease;

		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		will-change: transform;

		user-select: none;
	}

	.card-details {
		margin: 8.5px 5px .5em 5px;
		letter-spacing: 0.025em;

    .card-details-task,
    .card-details-name {
      font-weight: 800;
    }
    
    .card-details-description {
      font-weight: 500;
    }
	}

	@keyframes animation-fade-in {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}
